<template>
  <section class="sections-news-letter-subscribe">
    <div class="container">
      <!-- Heading -->
      <div class="row sections-news-letter-subscribe__wrapper">
        <div class="col-12 sections-news-letter-subscribe__chip">
          <div class="chip-wrapper">
            Subscribe
          </div>
        </div>
        <div class="col-12 sections-news-letter-subscribe__heading">
          <h2><strong>Stay</strong> in the know</h2>
        </div>
        <div class="col-12 sections-news-letter-subscribe__description">
          <p>Unlock a world of new home opportunities with our exclusive fortnightly news, promotions & tips from New Homes</p>
        </div>
        <div class="col-12">
          <transition name="fade">
            <div v-if="!shouldShowResponse" class="sections-news-letter-subscribe__form-wrapper">
              <form @keydown.prevent.enter="subscribe">
                <elements-inputs-text v-model="email" class="el-input-text" placeholder="Enter your email" @update:model-value="clearErrors" />
                <ElementsButtonsRegular label="subscribe" class="btn-subscribe" :loading="isLoading" @click="subscribe" />
                <transition name="fade">
                  <span v-if="error" class="form-errors">
                    {{ error }}
                  </span>
                </transition>
              </form>
            </div>
            <div v-else class="sections-news-letter-subscribe__confirmation-wrapper">
              <div class="check-icon-wrapper">
                <img v-lazy-load  :data-src="checkIcon" alt="Check icon">
              </div>
              <div class="confirmation-text">
                <h3>Thank you for subscribing!</h3>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import {Ref} from 'vue';
import checkIcon from '~/assets/images/icons/check.webp';
import globalApiHeaders from '~/composables/globalApiHeaders';

// DATA Definitions
// ---------------------------------------------------------------------------------------------------------------------
const email: Ref<string> = ref('');
const error: Ref<string | null> = ref(null);
const isLoading: Ref<boolean> = ref(false);
const nuxtApp = useNuxtApp();
const shouldShowResponse: Ref<boolean> = ref(false);

/**
 * Subscribe
 */
async function subscribe () {
  if (!email.value) {
    error.value = 'Please enter your email here to subscribe!';
  } else if (!(/.+@.+\..+/.test(email.value))) {
    error.value = 'Please enter a valid email address!';
  } else {
    error.value = null;
    isLoading.value = true;
    const baseUrl = nuxtApp.$config.public.apiUrl;
    const response = await fetch(baseUrl + '/public/cms/subscribe-to-newsletters', {
      method: 'POST',
      headers: globalApiHeaders(),
      body: JSON.stringify({email: email.value}),
    });

    setTimeout(() => {
      isLoading.value = false;

      if (response.status === 200) {
        shouldShowResponse.value = true;
      } else {
        error.value = 'Something went wrong. Please try again later!';
      }
    }, 800);
  }
}

/**
 * Clear error
 */
function clearErrors () {
  error.value = null;
}
</script>

<style lang="scss">
.sections-news-letter-subscribe {
  background: rgb(45,21,130);
  background: linear-gradient(10deg, rgba(var(--primary-dark),1) 5%, rgba(27,154,249,1) 100%);
  height: 380px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &__chip {
    display: flex;
    justify-content: center;
    align-items: center;

    .chip-wrapper {
      width: fit-content;
      padding: 8px 16px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 50px;
      color: white;
      font-size: 12px;
      margin: 20px 0 8px;
    }
  }

  &__heading {
    h2 {
      color: rgb(var(--text-light));
      font-size: 28px;
      font-weight: 300 !important;
      line-height: 1.2;
      text-align: center;
      margin-bottom: 5px;

      strong {
        font-weight: 700;
      }

      @media (max-width: 767px) {
        font-size: 30px;
      }
    }
  }

  &__description {
    width: 100%;

    p {
      color: rgb(var(--text-light));
      font-size: 14px;
      font-weight: 300 !important;;
      line-height: 1.2;
      margin-bottom: 30px;
      text-align: center;

      @media (max-width: 767px) {
        font-size: 18px;
      }
    }
  }

  &__form-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0 30px;

    form {
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      position: relative;

      .el-input-text {
        width: 320px;
        max-width: 100%;
        background: #fff;
        height: 50px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      .btn-subscribe {
        width: fit-content;
        height: 50px;
        padding: 0 20px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        background: rgb(var(--accent));
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      @media screen and (max-width: $grid-sm) {
        .el-input-text {
          width: 75%;
        }
      }
    }

    .form-errors {
      position: absolute;
      bottom: -24px;
      left: 0;
      color: #ff6d6d;
    }
  }

  &__confirmation-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;

    .check-icon-wrapper {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;

      img {
        width: 18px;
        height: 18px;
      }
    }

    .confirmation-text {
      h3 {
        color: white;
        font-size: 18px;
        font-weight: 300;
        line-height: 1.2;
        margin-bottom: 0;
      }
    }
  }
}
</style>
